import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';

@Injectable()
export class StorageService {

  constructor(
    private storage: Storage,
  ) {
  }

  async setAuthToken(data: string): Promise<string> {
    return await this.storage.set('token', data);
  }

  async getAuthToken(): Promise<string> {
    return await this.storage.get('token');
  }

  async clearAuthToken(): Promise<any> {
    return await this.storage.remove('token');
  }

}
