import { Component } from '@angular/core';
import { Platform } from '@ionic/angular';
import { StatusService } from '@core/services';
import { TabService } from '@core/http/services/tab.service';
import { jsBridge } from './wa';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  jsBridge_ = jsBridge;
  constructor(
    private platform: Platform,
    private status: StatusService,
    private api: TabService,
  ) {
    // this.api.getDriverJWT('18001941170', '123456').subscribe(res => {
    // // this.api.getDriverJWT('13162581161', '123456').subscribe(res => {
    //   this.status.token = res.jwt;
    // });
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(async () => {
      if ((window as any).setupWebViewJavascriptBridge) {
        this.registerScanResultFunction();
      }
    });
  }
    // 接受token
  registerScanResultFunction() {
    // (window as any).setupWebViewJavascriptBridge(bridge => {
      
    //   bridge.registerHandler('setToken', (data, fn) => {
    //     console.log("registerScanResultFunction111: ", data);
    //     this.status.token = data;
        // this.api.getDriverJWT(data).subscribe(res => {
        //   console.log("registerScanResultFunction22222: ", data);
        //   this.status.token = res.jwt;
        // });
    //   });
    // });
    (window as any).setupWebViewJavascriptBridge(bridge => {
      bridge.registerHandler('setPosition', (data, fn) => {
        const list = data.split(',');
        const lnt = list[0] ? Number(list[0]) : 0;
        const lat = list[1] ? Number(list[1]) : 0;
        this.status.userPosition = {
          lnt,
          lat
        };
      });
    });
  }
}
