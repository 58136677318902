import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import {
  CommonService,
  StatusService,
  UtilsService,
} from './services';
import {
  IonicModule,
  LoadingController,
} from '@ionic/angular';
import { StorageService } from './services/storage.service';

const PROVIDERS = [
  LoadingController,
  UtilsService,
  StatusService,
  StorageService,
];

export function throwIfAlreadyLoaded(parentModule: any, moduleName: string) {
  if (parentModule) {
    throw new Error(`${moduleName} has already been loaded. Import ${moduleName} in the AppModule only.`);
  }
}

@NgModule({
  imports: [CommonModule, FormsModule, IonicModule],
  providers: [
    ...PROVIDERS,
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}
