
import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';

@Injectable()
export class StatusService {
  userPosition: {lnt: number; lat: number} = {
    lnt: 0,
    lat: 0
  }
  token: string;
  setToken$: Subject<string> = new Subject();
}
