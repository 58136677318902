import { HttpInterceptor, HttpResponseBase, HttpRequest, HttpHandler, HttpErrorResponse, HttpEvent } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { UtilsService } from '@core/services';
import { StatusService } from '@core/services/status.service';
import { ToastController } from '@ionic/angular';
import { Observable, throwError, of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HttpClientService } from './http-client.service';

const CODEMESSAGE: { [key: number]: string } = {
  200: '服务器成功返回请求的数据。',
  201: '新建或修改数据成功。',
  202: '一个请求已经进入后台排队（异步任务）。',
  204: '删除数据成功。',
  400: '发出的请求有错误，服务器没有进行新建或修改数据的操作。',
  401: '用户没有权限（令牌、用户名、密码错误）。',
  403: '用户得到授权，但是访问是被禁止的。',
  404: '发出的请求针对的是不存在的记录，服务器没有进行操作。',
  406: '请求的格式不可得。',
  410: '请求的资源被永久删除，且不会再得到的。',
  422: '当创建一个对象时，发生一个验证错误。',
  500: '服务器发生错误，请检查服务器。',
  502: '网关错误。',
  503: '服务不可用，服务器暂时过载或维护。',
  504: '网关超时。',
};

@Injectable()
export class DefaultInterceptor implements HttpInterceptor {

  constructor(
    private injector: Injector,
    private router: Router,
    private util: UtilsService,
    private status: StatusService,
    public toastController: ToastController
    ) {
  }

  private get http(): HttpClientService {
    return this.injector.get(HttpClientService);
  }

  private goTo(url: string): void {
    this.router.navigateByUrl(url);
  }

  private checkStatus(ev: HttpResponseBase): void {
    if ((ev.status >= 200 && ev.status < 300) || ev.status === 401) {
      return;
    }

    const errortext = CODEMESSAGE[ev.status] || ev.statusText;
    this.util.showLoading({type: 'error', message: '请求失败'});
    setTimeout(() => this.util.hideLoading('error'), 1000);
  }

  private toLogin(): void {
    this.util.showLoading({type: 'error', message: '登录失败'});
    setTimeout(() => {
      this.util.hideLoading('error');
      (window as any).WebViewJavascriptBridge.callHandler('exitWeb');
    }, 1000);
  }

  private handleData(ev: HttpResponseBase, req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    // 可能会因为 `throw` 导出无法执行 `HttpService` 的 `end()` 操作
    if (ev.status > 0) {
      this.http.end();
    }
    // 业务处理：一些通用操作
    switch (ev.status) {
      case 200:
        break;
      case 400:
        if (!req.url.includes('driver/logistics/scan')) {
          const error = (ev as any).error || {};
          const { errormsg = '' } = error;
          this.presentToast(errormsg)
        }
        break;
      case 401:
        this.toLogin();
        break;
      case 403:
      case 404:
      case 500:
        const err = (ev as any).error || {};
        const errmsg = err.errormsg || '网络错误';
        this.presentToast(errmsg)
        break;
      default:
        if (ev instanceof HttpErrorResponse) {
          console.warn(
            '未知错误',
            ev,
          );
        }
        break;
    }
    if (ev instanceof HttpErrorResponse) {
      return throwError(ev);
    } else {
      return of(ev);
    }
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // 统一加上服务端前缀
    let newReq: HttpRequest<any>;
    const url = req.url;
    if (req.url.includes('/noauth/jwt')) {
      newReq = req.clone({
        url,
      });
    } else {
      newReq = req.clone({
        url,
        setHeaders: {
          authorization: `Bearer ${this.status.token}`,
        }
      });
    }

    return next.handle(newReq).pipe(
      mergeMap((ev) => {
        // 允许统一对请求错误处理
        if (ev instanceof HttpResponseBase) {
          return this.handleData(ev, newReq, next);
        }
        // 若一切都正常，则后续操作
        return of(ev);
      }),
      catchError((err: HttpErrorResponse) => this.handleData(err, newReq, next)),
    );
  }
  async presentToast(message: string) {
    const toast = await this.toastController.create({
      message,
      position: 'top',
      duration: 1000,
      cssClass: 'zhzg_toast'
    });
    toast.present();
  }
}
