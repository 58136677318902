const userAgent = window.navigator.userAgent;
const isAndroid = userAgent.indexOf('Android') > -1 || userAgent.indexOf('Adr') > -1;

/**
 * 配合 Android 使用时的初始化方法
 */
function androidFunction (callback: any) {
  if ((window as any).WebViewJavascriptBridge) {
      callback((window as any).WebViewJavascriptBridge);
  } else {
    document.addEventListener('WebViewJavascriptBridgeReady', () => {
      callback((window as any).WebViewJavascriptBridge);
    }, false);
  }
}

(window as any).setupWebViewJavascriptBridge = isAndroid ? androidFunction : null;

if ((window as any).setupWebViewJavascriptBridge) {
  (window as any).setupWebViewJavascriptBridge(function(bridge: any) {
    bridge.init(function(msg: any, responseCallback: any) {
      responseCallback(msg);
    })
  });
}

export const jsBridge = (window as any).setupWebViewJavascriptBridge;