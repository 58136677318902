import { Injectable } from '@angular/core';
import { Loading } from '@core/dao/Loading';
import { LoadingController } from '@ionic/angular';
import { StatusService } from './status.service';

@Injectable()
export class UtilsService {

  successLoading: any;
  loadingLoading: any = this.loadingController.create({
    cssClass: 'zhzg_page_loading',
    message: null,
  });
  errorLoading: any;
  isShowLoading: boolean;
	_keyStr = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';

  constructor(
    private loadingController: LoadingController,
  ) { }

  encode (input) {
		let output = '';
		let chr1, chr2, chr3, enc1, enc2, enc3, enc4;
		let i = 0;
		input = this._utf8_encode(input);
		while (i < input.length) {
			chr1 = input.charCodeAt(i++);
			chr2 = input.charCodeAt(i++);
			chr3 = input.charCodeAt(i++);
			enc1 = chr1 >> 2;
			enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
			enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
			enc4 = chr3 & 63;
			if (isNaN(chr2)) {
				enc3 = enc4 = 64;
			} else if (isNaN(chr3)) {
				enc4 = 64;
			}
			output = output +
			this._keyStr.charAt(enc1) + this._keyStr.charAt(enc2) +
			this._keyStr.charAt(enc3) + this._keyStr.charAt(enc4);
		}
		return output;
  }
  _utf8_encode (string) {
		string = string.replace(/\r\n/g,'\n');
		let utftext = '';
		for (let n = 0; n < string.length; n++) {
			let c = string.charCodeAt(n);
			if (c < 128) {
				utftext += String.fromCharCode(c);
			} else if((c > 127) && (c < 2048)) {
				utftext += String.fromCharCode((c >> 6) | 192);
				utftext += String.fromCharCode((c & 63) | 128);
			} else {
				utftext += String.fromCharCode((c >> 12) | 224);
				utftext += String.fromCharCode(((c >> 6) & 63) | 128);
				utftext += String.fromCharCode((c & 63) | 128);
			}
 
		}
		return utftext;
	}

  showLoading(params: Loading) {
    if (!this.isShowLoading) {
      if (params.type === 'success') {
        this.initSuccessLoading(params.message);
      } else if (params.type === 'loading') {
        this.initLoadingLoading(params.message);
      } else if (params.type === 'error') {
        this.initErrorLoading(params.message);
      }
    }
  }

  hideLoading(type: 'success' | 'loading' | 'error') {
    this.isShowLoading = false;
    if (type === 'success') {
      if (this.successLoading) this.successLoading.dismiss();
    } else if (type === 'loading') {
      if (this.loadingLoading) {
        this.loadingLoading.dismiss();
      };
    } else if (type === 'error') {
      if (this.errorLoading) this.errorLoading.dismiss();
    }
  }

  async initSuccessLoading(message: string) {
    this.isShowLoading = true;
    this.successLoading = await this.loadingController.create({
      cssClass: 'zhzg_page_loading zhzg_success_loading',
      showBackdrop: false,
      message,
    });
    await this.successLoading.present();
  }

  async initErrorLoading(message: string) {
    this.isShowLoading = true;
    this.errorLoading = await this.loadingController.create({
      cssClass: 'zhzg_page_loading zhzg_error_loading',
      showBackdrop: false,
      message,
    });
    await this.errorLoading.present();
  }

  async initLoadingLoading(message: string) {
    this.isShowLoading = true;
    this.loadingLoading = await this.loadingController.create({
      cssClass: 'zhzg_page_loading',
      message,
    });
    await this.loadingLoading.present();
  }

}
