import { Injectable } from '@angular/core';
import { UtilsService } from '@core/services';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HttpClientService } from '../http-client.service';

const BASE_URL = environment.baseUrl;


@Injectable()
export class TabService {
  constructor(
    private http: HttpClientService,
    private utils: UtilsService,
  ) {}

  // 获取司机登录的JWT
  getDriverJWT(param) {
    const url = BASE_URL + '/noauth/jwt';
    const data = { service: 'driver' };

    return this.http.get(url, data, {
      headers: {Authorization: `Basic ${param}`}
    });
  };
  // 获取班线已完成任务列表
  getClassLineCompList(page_number: number, page_size: number) {
    const url = `${BASE_URL}/driver/line-logistics/completed/page`;
    return this.http.get(url, {page_number, page_size}).pipe(map(res => {
      res.list.forEach(item => {
        item.sites = this.routeTypeTransfer(item.sites, item.up_end_address_id);
      });
      return res;
    }));
  }
  // 获取班线除了已完成任务的其他列表
  getClassLineExceptCompList(state: string) {
    const url = `${BASE_URL}/driver/line-logistics/list`;
    return this.http.get(url, {state}).pipe(map(res => {
      res.forEach(item => {
        item.sites = this.routeTypeTransfer(item.sites, item.up_end_address_id);
      });
      return res;
    }));
  }
  // 获取专线任务列表
  getdeDicatedTask(type: string, page_number: number, page_size: number) {
    const url = `${BASE_URL}/driver/dedicated-logistics/get-task-list`;
    return this.http.get(url, {type, page_number, page_size});
  }
  // 获取工作任务数量
  gettaskList() {
    const url = `${BASE_URL}/driver/dedicated-logistics/get-task-num`;
    return this.http.get(url);
  }

  // 获取拒绝接单原因
  getRefuseList(): Observable<string[]> {
    const url = `${BASE_URL}/driver/line-logistics/reject/reason/list`;
    return this.http.get(url);
  }
  // 拒绝班线任务
  refuseClassOrder(id: number, reason: string) {
    const url = `${BASE_URL}/driver/line-logistics/reject`;
    return this.http.post(url, {id, reason});
  }
  // 开始班线任务
  startClassOrder(id: number) {
    const url = `${BASE_URL}/driver/line-logistics/start`;
    return this.http.post(url, {id});
  }
  // 接受班线任务
  receivedClassOrder(id: number) {
    const url = `${BASE_URL}/driver/line-logistics/receive`;
    return this.http.post(url, {id});
  }

  // 接受或拒绝任务
  receivedOrder(logistics_id: number, type: number, reason?: string) {
    let params = null
    if (type === 0) {
      params = {
        logistics_id,
        type,
        reason
      }
    } else {
      params = {
        logistics_id,
        type
      };
    }
    const url = `${BASE_URL}/driver/dedicated-logistics/accept-order`;
    return this.http.post(url, params);
  }

  // 开始任务
  startOrder(logistics_id: number) {
    const url = `${BASE_URL}/driver/dedicated-logistics/start-logistics`;
    return this.http.post(url, { logistics_id });
  }


  routeTypeTransfer(initSites: any[], upEndAddressId: number): any[] {
    const sites = [...initSites];
    if (upEndAddressId) {
      const ind = sites.findIndex(data => data.address_id === upEndAddressId);
      if (ind && sites[ind + 1].address_id === upEndAddressId) {
        sites.splice(ind, 1);
      }
    }
    // if (item.sites[0].address_id === item.sites[item.sites.length - 1].address_id) {
    //   item.sites.pop();
    // }
    const objIndex = {};
    let maxIndex = 0;
    sites.forEach((res, i) => {
      if (!objIndex[res.address_id]) {
        maxIndex = maxIndex + 1;
        res.index = maxIndex;
        objIndex[res.address_id] = maxIndex;
        res.down = false;
      } else {
        res.index = objIndex[res.address_id];
        res.down = true;
      }
    });
    return sites;
  }

}
