import { Injectable } from '@angular/core';
import { HttpClientService } from '@core/http/http-client.service';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

const BASE_URL = environment.baseUrl;

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(
    private http: HttpClientService
  ) {}

  // 根据图片名称获取图片内容
  getPictureBase64(name: string): Observable<{data: string}> {
    return this.http.get(`${BASE_URL}/driver/file/picture/get`, { name });
  }
}
