import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { switchMap, catchError, tap } from 'rxjs/operators';
import { UtilsService } from '../services/utils.service';

export type _HttpHeaders = HttpHeaders | { [header: string]: string | string[] };
export type HttpObserve = 'body' | 'events' | 'response';


@Injectable({
  providedIn: 'root'
})
export class HttpClientService {

  constructor(
    private http: HttpClient,
    private util: UtilsService
  ) {}

  private _loading = false;

  get loading(): boolean {
    return this._loading;
  }

  begin() {
    Promise.resolve(null).then(() => {
      if (!this._loading) {
        this._loading = true;
        this.util.showLoading({type: 'loading', message: '加载中'});
      }
    });
  }

  end() {
    Promise.resolve(null).then(() => {
      if (this._loading) {
        setTimeout(() => {
          this._loading = false;
          this.util.hideLoading('loading');
        }, 500)
      }
    });
  }

  parseBody(body: any): any {
    const newBody: any = {};
    if (body instanceof FormData) {
      return body;
    }
    Object.keys(body).forEach(key => {
      const _data = body[key];
      // 忽略空值
      if (_data == null) { return; }
      newBody[key] = _data;
    });
    return newBody;
  }

  parseParams(params: any): HttpParams {
    const newParams: any = {};
    if (params instanceof HttpParams) {
      return params;
    }

    Object.keys(params).forEach(key => {
      const _data = params[key];
      // 忽略空值
      if (_data == null) { return; }
      newParams[key] = _data;
    });
    return new HttpParams({ fromObject: newParams });
  }

  get(
    url: string,
    params?: any,
    options?: {
      headers?: _HttpHeaders;
      observe?: 'body' | 'events' | 'response';
      reportProgress?: boolean;
      responseType?: 'arraybuffer' | 'blob' | 'json' | 'text';
      withCredentials?: boolean;
    },
  ): Observable<any>;

  get<T>(
    url: string,
    params?: any,
    options?: {
      headers?: _HttpHeaders;
      observe: 'body';
      reportProgress?: boolean;
      responseType?: 'json';
      withCredentials?: boolean;
    },
  ): Observable<T>;

  get(
    url: string,
    params: any,
    options: {
      headers?: _HttpHeaders;
      observe?: 'body' | 'events' | 'response';
      reportProgress?: boolean;
      responseType?: 'arraybuffer' | 'blob' | 'json' | 'text';
      withCredentials?: boolean;
    } = {},
  ): Observable<any> {
    return this.request('GET', url, {
      params,
      ...options,
    });
  }

  post(
    url: string,
    body?: any,
    params?: any,
    options?: {
      headers?: _HttpHeaders;
      observe?: 'body' | 'events' | 'response';
      reportProgress?: boolean;
      responseType?: 'arraybuffer' | 'blob' | 'json' | 'text';
      withCredentials?: boolean;
    },
  ): Observable<any>;

  post<T>(
    url: string,
    body?: any,
    params?: any,
    options?: {
      headers?: _HttpHeaders;
      observe: 'response';
      reportProgress?: boolean;
      responseType?: 'json';
      withCredentials?: boolean;
    },
  ): Observable<T>;

  post(
    url: string,
    body: any,
    params: any,
    options: {
      headers?: _HttpHeaders;
      observe?: 'body' | 'events' | 'response';
      reportProgress?: boolean;
      responseType?: 'arraybuffer' | 'blob' | 'json' | 'text';
      withCredentials?: boolean;
    } = {},
  ): Observable<any> {
    return this.request('POST', url, {
      body,
      params,
      ...options,
    });
  }

  delete<T>(
    url: string,
    params?: any,
    options?: {
      headers?: _HttpHeaders;
      observe?: 'body' | 'events' | 'response';
      reportProgress?: boolean;
      responseType?: 'arraybuffer' | 'blob' | 'json' | 'text';
      withCredentials?: boolean;
    },
  ): Observable<T>;

  delete(
    url: string,
    params: any,
    options: {
      headers?: _HttpHeaders;
      observe?: 'body' | 'events' | 'response';
      reportProgress?: boolean;
      responseType?: 'arraybuffer' | 'blob' | 'json' | 'text';
      withCredentials?: boolean;
    } = {},
  ): Observable<any> {
    return this.request('DELETE', url, {
      params,
      ...options,
    });
  }

  put<T>(
    url: string,
    body?: any,
    params?: any,
    options?: {
      headers?: _HttpHeaders;
      observe: 'response';
      reportProgress?: boolean;
      responseType?: 'json';
      withCredentials?: boolean;
    },
  ): Observable<T>;

  put(
    url: string,
    body: any,
    params: any,
    options: {
      headers?: _HttpHeaders;
      observe?: 'body' | 'events' | 'response';
      reportProgress?: boolean;
      responseType?: 'arraybuffer' | 'blob' | 'json' | 'text';
      withCredentials?: boolean;
    } = {},
  ): Observable<any> {
    return this.request('PUT', url, {
      body,
      params,
      ...options,
    });
  }

  form<T>(
    url: string,
    body?: any,
    params?: any,
    options?: {
      headers?: _HttpHeaders;
      observe: 'response';
      reportProgress?: boolean;
      responseType?: 'json';
      withCredentials?: boolean;
    },
  ): Observable<T>;

  form(
    url: string,
    body: any,
    params: any,
    options: {
      headers?: _HttpHeaders;
      observe?: 'body' | 'events' | 'response';
      reportProgress?: boolean;
      responseType?: 'arraybuffer' | 'blob' | 'json' | 'text';
      withCredentials?: boolean;
    } = {},
  ): Observable<any> {
    return this.request('POST', url, {
      body,
      params,
      ...options,
      headers: {
        'content-type': `application/x-www-form-urlencoded`,
      },
    });
  }

  request(
    method: string,
    url: string,
    options: {
      body?: any;
      headers?: _HttpHeaders;
      params?: any;
      observe?: HttpObserve;
      reportProgress?: boolean;
      responseType?: 'arraybuffer' | 'blob' | 'json' | 'text';
      withCredentials?: boolean;
    } = {},
  ): Observable<any> {
    if (options.body) {
      options.body = this.parseBody(options.body);
    }
    if (options.params) {
      options.params = this.parseParams(options.params);
    }
    return of(null).pipe(
      tap(() => this.begin()),
      switchMap(() => this.http.request(method, url, options)),
      tap(() => this.end()),
      catchError(res => {
        this.end();
        return throwError(res);
      }),
    );
  }
}
